import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Contact: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const apiEndpoint =
      process.env.NODE_ENV === "production"
        ? "/.netlify/functions/send-email" // Replace with your production API URL
        : "http://localhost:3001/send-email";

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          from: "no-reply@rbelful.com",
          to: "rbelful@gmail.com",
          subject: `New message from ${email}`,
          message: message,
        }),
      });

      if (response.ok) {
        setStatus(t("emailResponseSuccess"));
        setEmail("");
        setMessage("");
      } else {
        setStatus(t("emailResponseError"));
      }
    } catch (error) {
      setStatus(t("emailResponseError"));
    }
  };

  return (
    <div className="container mx-auto p-4 ">
      <h1 className="text-2xl font-bold mb-4"> {t("contactTitle")}</h1>
      <p className="mt-2 text-justify text-xl">{t("contactBodyPart1")}</p>
      <p className="mt-2 text-justify text-xl">{t("contactBodyPart2")}</p>
      <p className="mt-2 text-justify text-xl">{t("contactBodyPart3")}</p>
      <form onSubmit={handleSubmit}>
        <div className="my-4 ">
          <label
            className="block text-gray-700 text-xl font-bold mb-2"
            htmlFor="email"
          >
            {t("emailTitle")}
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("emailPlaceholder")}
            required
            className="shadow appearance-none border rounded w-full md:w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-xl font-bold mb-2"
            htmlFor="message"
          >
            {t("messageTitle")}
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            placeholder={t("messagePlaceholder")}
            className="shadow appearance-none border rounded w-full md:w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows={5}
          />
        </div>
        <div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {t("sendEmail")}
          </button>
        </div>
        {status && <p className="mt-4 text-xl">{status}</p>}
      </form>
    </div>
  );
};

export default Contact;
