import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">{t("title")}</h1>
      <p className="mt-4">{t("introText")}</p>

      <h2 className="text-xl font-semibold mt-6">{t("section1Title")}</h2>
      <h3 className="text-lg font-semibold mt-4">{t("personalDataTitle")}</h3>
      <p className="mt-2">{t("personalDataText")}</p>
      <ul className="list-disc list-inside mt-2">
        <li>{t("personalDataItem1")}</li>
        <li>{t("personalDataItem2")}</li>
        <li>{t("personalDataItem3")}</li>
      </ul>

      <h3 className="text-lg font-semibold mt-4">{t("usageDataTitle")}</h3>
      <p className="mt-2">{t("usageDataText")}</p>
      <ul className="list-disc list-inside mt-2">
        <li>{t("usageDataItem1")}</li>
        <li>{t("usageDataItem2")}</li>
        <li>{t("usageDataItem3")}</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6">{t("section2Title")}</h2>
      <p className="mt-2">{t("section2Text")}</p>
      <ul className="list-disc list-inside mt-2">
        <li>{t("section2Item1")}</li>
        <li>{t("section2Item2")}</li>
        <li>{t("section2Item3")}</li>
        <li>{t("section2Item4")}</li>
        <li>{t("section2Item5")}</li>
        <li>{t("section2Item6")}</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6">{t("section3Title")}</h2>
      <p className="mt-2">{t("section3Text")}</p>
      <ul className="list-disc list-inside mt-2">
        <li>{t("section3Item1")}</li>
        <li>{t("section3Item2")}</li>
        <li>{t("section3Item3")}</li>
        <li>{t("section3Item4")}</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6">{t("section4Title")}</h2>
      <p className="mt-2">{t("section4Text")}</p>

      <h2 className="text-xl font-semibold mt-6">{t("section5Title")}</h2>
      <p className="mt-2">{t("section5Text")}</p>

      <h2 className="text-xl font-semibold mt-6">{t("section6Title")}</h2>
      <p className="mt-2">{t("section6Text")}</p>
      <ul className="list-disc list-inside mt-2">
        <li>{t("section6Item1")}</li>
        <li>{t("section6Item2")}</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6">{t("section7Title")}</h2>
      <p className="mt-2">{t("section7Text")}</p>
    </div>
  );
};

export default PrivacyPolicy;
