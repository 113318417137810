import React from "react";
import { useTranslation } from "react-i18next";

const OurMission: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-4">
      <h1 className="mb-4 text-2xl">{t("ourMissionTitle")}</h1>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody1")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody2")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody3")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody4")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody5")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody6")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody7")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody8")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody9")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourMissionBody10")}</p>

      <h1 className="my-8 text-2xl">{t("howWeDoItTitle")}</h1>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody1")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody2")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody3")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody4")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody5")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody6")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody7")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody8")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody9")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody10")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody11")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody12")}</p>
      <p className="mt-2 text-justify text-xl">{t("howWeDoItBody13")}</p>

      <h1 className="my-8 text-2xl">{t("ourValuesTitle")}</h1>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody1")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody2")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody3")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody4")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody5")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody6")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody7")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody8")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody9")}</p>
      <p className="mt-2 text-justify text-xl">{t("ourValuesBody10")}</p>
    </div>
  );
};

export default OurMission;
