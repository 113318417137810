import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabase";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const handleWaitingListClick = async () => {
    if (!email) {
      setStatus(t("emailResponseError")); // You may want to add a specific error message for empty emails
      return;
    }

    const { error } = await supabase
      .from("waiting_list")
      .insert({ email: email, language: i18n.language });
    if (error) {
      alert(error.details);
    } else {
      const apiEndpoint =
        process.env.NODE_ENV === "production"
          ? "/.netlify/functions/send-email"
          : "http://localhost:3001/send-email";

      try {
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from: "no-reply@rbelful.com",
            to: email,
            subject: t("joiningListEmailSubject"),
            message: t("joiningListEmailMessage"),
          }),
        });

        if (response.ok) {
          setStatus(t("emailResponseSuccess"));
          setEmail("");
        } else {
          setStatus(t("emailResponseError"));
        }
      } catch (error) {
        setStatus(t("emailResponseError"));
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">{t("homeHeaderTitle")}</h1>
      <p className="my-4 text-xl">{t("homeHeaderBody1")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody2")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody3")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody4")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody5")}</p>

      <p className="my-4 text-xl">{t("downloadBody")}</p>
      <div className="my-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t("homeEmailPlaceholder")}
          className="shadow appearance-none border rounded w-full md:w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="my-4">
        <button
          onClick={handleWaitingListClick}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
        >
          {t("waitingListButton")}{" "}
          {/* Ensure you add this key to the translation files */}
        </button>
      </div>
      <div className="my-4">
        {status && <p className="mt-4 text-xl">{status}</p>}
      </div>

      <img
        src={require(`../images/early_access.png`)}
        alt="Login Screen"
        className="border-2 rounded border-green-600 mt-6 w-full max-w-xs mx-auto"
        style={{ height: "auto" }}
      />
    </div>
  );
};

export default Home;
