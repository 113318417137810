import React from "react";
import { useTranslation } from "react-i18next";

const Tutorial: React.FC = () => {
  const { t, i18n } = useTranslation();

  const sections = [
    {
      titleKey: "signTitle",
      bodyKey: "loginBody",
      image: require(`../images/login-screen/${
        i18n.language.length === 2
          ? i18n.language
          : i18n.language[0] + i18n.language[1]
      }.png`),
      alt: "Login Screen",
    },
    {
      titleKey: "userDetailsTitle",
      bodyKey: "userDetailsFilled",
      images: [
        require(`../images/user-details-empty/${
          i18n.language.length === 2
            ? i18n.language
            : i18n.language[0] + i18n.language[1]
        }.png`),
        require(`../images/user-details-filled/${
          i18n.language.length === 2
            ? i18n.language
            : i18n.language[0] + i18n.language[1]
        }.png`),
      ],
      alts: ["User Details Empty", "User Details Filled"],
    },
    {
      titleKey: "uploadTitle",
      bodyKey: "uploadBody",
      image: require(`../images/upload-take-picture/${
        i18n.language.length === 2
          ? i18n.language
          : i18n.language[0] + i18n.language[1]
      }.png`),
      alt: "Upload or Take",
    },
    {
      titleKey: "registrationTitle",
      bodyKey: "registrationBody",
      images: [
        require(`../images/registration-menu-empty/${
          i18n.language.length === 2
            ? i18n.language
            : i18n.language[0] + i18n.language[1]
        }.png`),
        require(`../images/registration-menu-filled/${
          i18n.language.length === 2
            ? i18n.language
            : i18n.language[0] + i18n.language[1]
        }.png`),
      ],
      alts: ["Registration Menu Empty", "Registration Menu Filled"],
    },
    {
      titleKey: "waitingTitle",
      bodyKey: "waitingBody",
      image: require(`../images/waiting-for-meeting/${
        i18n.language.length === 2
          ? i18n.language
          : i18n.language[0] + i18n.language[1]
      }.png`),
      alt: "Waiting for Meeting",
    },
    {
      titleKey: "discoverTitle",
      bodyKey: "discoverBody",
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">{t("tutorial")}</h1>
      {sections.map((section, index) => (
        <section key={index} className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">{t(section.titleKey)}</h2>
          <p className="mb-4 text-xl">{t(section.bodyKey)}</p>
          {section.images ? (
            <div className="flex flex-col items-center sm:flex-row sm:justify-center sm:space-x-4">
              {section.images.map((image, i) => (
                <img
                  key={i}
                  src={image}
                  alt={section.alts[i]}
                  className="border-2 rounded border-green-600 w-full max-w-xs sm:w-1/2 mb-4 sm:mb-0"
                  style={{ height: "auto" }}
                />
              ))}
            </div>
          ) : (
            section.image && (
              <img
                src={section.image}
                alt={section.alt}
                className="border-2 rounded border-green-600 w-full max-w-xs mx-auto"
                style={{ height: "auto" }}
              />
            )
          )}
        </section>
      ))}
    </div>
  );
};

export default Tutorial;
