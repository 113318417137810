import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const constants = {
  routes: {
    home: "/",
    ourMission: "/our-mission",
    privacyPolicy: "/privacy-policy",
    tutorial: "/tutorial",
    contact: "/contact",
    donation: "/donation",
  },
};

const flags = [
  { code: "pt", emoji: "🇵🇹", label: "Portuguese" },
  { code: "en", emoji: "🇬🇧", label: "English" },
  { code: "fr", emoji: "🇫🇷", label: "French" },
  { code: "it", emoji: "🇮🇹", label: "Italian" },
  { code: "es", emoji: "🇪🇸", label: "Spanish" },
  { code: "nl", emoji: "🇳🇱", label: "Dutch" },
  { code: "de", emoji: "🇩🇪", label: "German" },
];

const Navbar: React.FC<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => {
  const [activeLink, setActiveLink] = useState<string>(constants.routes.home);
  const [showFlags, setShowFlags] = useState<boolean>(false);
  const [selectedFlag, setSelectedFlag] = useState<string>(""); // Default to English flag
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Set the default flag based on i18n.language
    setSelectedFlag(i18n.language);
  }, [i18n.language]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleFlagClick = (code: string) => {
    setSelectedFlag(code);
    i18n.changeLanguage(code);
    setShowFlags(false); // Hide flag options after selection
  };

  return (
    <nav className="border-white border-b-2 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div className="flex space-x-4">
          <Link
            to="https://rbelful.com/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <span className="self-center text-2xl font-semibold font-sans whitespace-nowrap dark:text-white font-itim">
              Rbelful
            </span>
          </Link>
        </div>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggleNavbar}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${isOpen ? "block" : "hidden"} w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg bg-transparent md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:border-gray-700">
            <li>
              <Link
                to={constants.routes.home}
                className={`block py-2 px-3 rounded ${
                  activeLink === constants.routes.home
                    ? "text-white bg-blue-700 md:bg-transparent md:text-blue-700"
                    : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }`}
              >
                {t("home")}
              </Link>
            </li>
            <li>
              <Link
                to={constants.routes.ourMission}
                className={`block py-2 px-3 rounded ${
                  activeLink === constants.routes.ourMission
                    ? "text-white bg-blue-700 md:bg-transparent md:text-blue-700"
                    : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }`}
              >
                {t("ourMission")}
              </Link>
            </li>
            <li>
              <Link
                to={constants.routes.tutorial}
                className={`block py-2 px-3 rounded ${
                  activeLink === constants.routes.tutorial
                    ? "text-white bg-blue-700 md:bg-transparent md:text-blue-700"
                    : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }`}
              >
                {t("tutorial")}
              </Link>
            </li>
            <li>
              <Link
                to={constants.routes.contact}
                className={`block py-2 px-3 rounded ${
                  activeLink === constants.routes.contact
                    ? "text-white bg-blue-700 md:bg-transparent md:text-blue-700"
                    : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }`}
              >
                {t("contact")}
              </Link>
            </li>
            <li>
              <Link
                to={constants.routes.donation}
                className={`block py-2 px-3 rounded ${
                  activeLink === constants.routes.donation
                    ? "text-white bg-blue-700 md:bg-transparent md:text-blue-700"
                    : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }`}
              >
                {t("donation")}
              </Link>
            </li>
            {/* Language Selector */}
            <li className="flex items-center justify-center space-x-2">
              {!showFlags ? (
                <div className="flex items-center">
                  <span className="font-semibold">{t("nation")}</span>
                  <button
                    className=" p-1 ml-2 focus:outline-none flex items-center"
                    onClick={() => setShowFlags(true)}
                    aria-label="Select Language"
                  >
                    {flags.find((flag) => flag.code === selectedFlag)?.emoji}
                  </button>
                </div>
              ) : (
                <div className="flex space-x-2">
                  {flags.map((flag) => (
                    <button
                      key={flag.code}
                      className={` p-1 ${
                        i18n.language === flag.code ? "opacity-50" : ""
                      }`}
                      onClick={() => handleFlagClick(flag.code)}
                      aria-label={`Switch to ${flag.label}`}
                    >
                      {flag.emoji}
                    </button>
                  ))}
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
