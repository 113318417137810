import React from "react";
import { useTranslation } from "react-i18next";

const Donation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-4">
      <h1 className="mb-4 text-2xl">{t("donationTitle")}</h1>
      <p className="mt-2 text-justify text-xl">{t("donationBody1")}</p>
      <p className="mt-2 text-justify text-xl">{t("donationBody2")}</p>
      <p className="mt-2 text-justify text-xl">{t("donationBody3")}</p>
      <p className="mt-2 text-justify text-xl">{t("donationBody4")}</p>
      <p className="mt-2 text-justify text-xl">{t("donationBody5")}</p>
      <p className="mt-20 text-justify text-xl">{t("donationBodyQuote1")}</p>
      <p className="mt-2 text-justify text-xl">{t("donationBodyQuote2")}</p>
      <p className="mt-2 text-justify text-xl">{t("donationBodyQuote3")}</p>
      <p className="mt-2 text-justify text-xl">
        {t("donationBodyQuoteAuthor")}
      </p>

      {/* Title for QR Code */}
      <h2 className="mt-6 text-xl font-semibold">{t("scanOrClick")}</h2>

      {/* QR Code Image with Link */}
      <div className="mt-4 flex justify-center">
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=BPW85VX2WXSJE"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../images/donation-QRCode.png")}
            alt="QR Code for Donation"
            className="w-32 h-32"
          />
        </a>
      </div>
    </div>
  );
};

export default Donation;
